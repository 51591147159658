'use strict'

const {floor} = require("mathjs");
const moment = require("moment");

const concat = require("lodash/concat");
const filter = require("lodash/filter");
const find = require("lodash/find");
const findIndex = require("lodash/findIndex");
const includes = require("lodash/includes");
const map = require("lodash/map");
const slice = require("lodash/slice");
const sortBy = require("lodash/sortBy");

const participantLib = require('./participant')

module.exports = {
  getSetLabel: function(rotationType) {
    return rotationType === 'rotation' ? 'set' : 'panel'
  },

  getSetRotations: function(session, set, rotationType) {
    let rotations = session.rotations

    if (rotationType !== 'mixed' && session.setProperties) {
      rotations = session.setProperties[set-1].rotations
    }

    return rotations
  },

  /**
   * Find out secondary rotation type
   * @param session
   * @param context discipline, exerciseTypes
   * @returns rotationType
   */
  getSessionRotationTypeSecondary: function(session, context) {
    const mixedRotationTypes = context.discipline.config.mixedRotationTypes || false

    let rotationType = null

    if (mixedRotationTypes && session) {

      if (session.exerciseTypes?.length) {
        const exerciseTypes = context.exerciseTypes

        let mixed = false
        session.exerciseTypes.forEach(etId => {
          const et = exerciseTypes.find(type => type.id === etId)

          const ert = et.config.rotationType || rotationType

          if (ert === 'mixed') {
            mixed = true
          }
        })

        if (mixed) {
          rotationType = 'mixed'
        } else {
          rotationType = context.discipline.rotationType
        }
      }
    }

    return rotationType
  },


  getBlockTitle: function(block, session, options, exerciseTypes, t) {
    const rotationType = options.rotationType
    const locale = options.locale

    let setLabel = this.getSetLabel(rotationType)
    let blockNr = block.index + 1
    if (rotationType === 'block') {
      blockNr = session.sets * block.index + block.set
    }

    let title = t('block', locale) + ' ' + blockNr
    if (options.addExerciseType && rotationType === 'rotation') {
      let rotation = options.rotation
      if (rotation === undefined) rotation = 0
      const exercise = this.getBlockExerciseType(session, block, rotation, exerciseTypes)

      title += ' ' + t('exercise.type.' + exercise, locale)
    }

    if (options.full && rotationType === 'block') {
      title += ' (' + t(setLabel, locale) + ' ' + block.set + ')'
    }
    return title
  },

  getSessionCategories: function(session, categories) {
    const ids = map(session.categories, sessionCategory => sessionCategory.categoryId)
    const sessionCategories = categories.filter(c => includes(ids, c.id))

    return sortBy(sessionCategories, 'index')
  },

  getSessionExerciseTypes(session, categories, formats) {
    let exerciseTypes = []
    session.categories.forEach(sc => {
      const category = categories.find(item => item.id === sc.categoryId)
      const categoryRound = find(category.rounds, item => item.roundIndex === session.roundIndex)
      const format = formats.find(item => item.id === categoryRound.formatId)
      const catExerciseTypes = map(format.exercises, item => item.exerciseTypeId)
      exerciseTypes = exerciseTypes.concat(catExerciseTypes)
    })

    return Array.from(new Set(exerciseTypes))
  },

  getSessionEffectiveExerciseTypes(session, categories, formats) {
    return session.exerciseTypes?.length ? session.exerciseTypes : this.getSessionExerciseTypes(session, categories, formats)
  },

  getExerciseTime: function(config, roundIndex, categoryId, exerciseTypeId, context) {
    const category = context.categories.find(c => c.id === categoryId)
    const catRound = category.rounds.find(r => r.roundIndex === roundIndex)

    let exerciseTime = config.exerciseTime

    if (catRound.exerciseTime) {
      exerciseTime = catRound.exerciseTime
    }

    if (catRound?.exerciseTiming && exerciseTypeId) {
      const te = find(catRound.exerciseTiming, et => et.type === exerciseTypeId)
      if (te?.time) {
        exerciseTime = te.time
      }
    }

    return exerciseTime
  },

  getBlockExerciseType: function(session, block, rotationType, exerciseTypes, rotation = 0) {
    const rotations = this.getSetRotations(session, block.set, rotationType)
    const restRotations = rotations - exerciseTypes.length
    const exerciseIndex = (block.index + rotation) % session.rotations

    // is it a rest rotation?
    if (restRotations) {
      const skipGap = floor(rotations / restRotations)

      for (let i=0; i < restRotations; i++) {
        const restIndex = i*skipGap + (skipGap-1)
        exerciseTypes.splice(restIndex, 0, 'rest')
      }
    }

    return exerciseTypes[exerciseIndex]
  },

  getSessionTimes: function(session, rotationType) {
    let times = session.timeTable

    if (rotationType === 'block') {
      times = times.filter(i => i.time && i.key !== 'competition')
    } else {
      times = times.filter(i => i.time && i.key !== 'rotation-start-0')
    }

    return sortBy(times, "time")
  },

  getRotationStartTime(session, rotation) {
    let time = null

    if (session.timeTable) {
      const key = 'rotation-start-' + rotation
      let item = session.timeTable.find(i => i.key === key)

      if (item) {
        time = item.time
      } else {
        item = session.timeTable.find(i => i.key === 'competition')
        if (item && rotation === 0) {
          time = item.time
        }
      }
    }
    return time ? moment(time, 'HH:mm') : null
  },

  setIterationStartTime(session, rotation, iteration, iterationStartTime) {
    const key = 'rotation-' + rotation + '-exercise-' + iteration
    const item = session.timeTable?.find(i => i.key === key)

    if (item) {
      iterationStartTime = moment(item.time, 'HH:mm')
    }
    return iterationStartTime
  },

  deriveSet: function(session, categoryId, exerciseTypeId) {
    const sCategory = find(session.categories, cat => cat.categoryId === categoryId)

    if (sCategory) {
      const sCatExerciseType = find(sCategory.exercises, i => i.exerciseTypeId === exerciseTypeId)
      if (sCatExerciseType) {
        return sCatExerciseType.set
      }
    }
    return null
  },

  orderBlockParticipations(participations, blockParticipations, block, session, rotationType, exerciseTypes, activeRotation) {
    let blockParticipationsSorted = []
    if (session.rotationType === 'schedule') {
      const exerciseTypeId = this.getBlockExerciseType(session, block, rotationType, exerciseTypes, activeRotation)
      const order = block.exerciseOrders?.[exerciseTypeId]

      if (order) {
        blockParticipationsSorted = map(order, participationId =>
          find(blockParticipations, blockParticipation => blockParticipation.participationId === participationId))
      }
      return blockParticipationsSorted
    }

    blockParticipationsSorted = sortBy(blockParticipations, 'index')

    // shift participations when rotationType is alternating
    if (session.rotationType === 'alternating' && blockParticipationsSorted.length > 1) {
      let shift = activeRotation
      if (shift > 0) {
        // check if a rest rotation exists
        if (exerciseTypes.length < session.rotations) {
          if (block.index >= session.rotations - activeRotation) {
            shift -= 1
          }
        }

        const blockParticipationsPresent = filter(blockParticipationsSorted, item => {
          const participation = participations.find(p => p.id === item.participationId)
          const status = participantLib.getStatus(participation, session.roundIndex)
          return status === 'present'
        });

        shift %= blockParticipationsPresent.length
        if (shift > 0) {
          const firstId = blockParticipationsPresent[shift].id
          const shiftIndex = findIndex(blockParticipationsSorted, item => item.id === firstId)
          const partTwo = slice(blockParticipationsSorted, 0, shiftIndex)
          const partOne = slice(blockParticipationsSorted, shiftIndex, blockParticipationsSorted.length)
          blockParticipationsSorted = concat(partOne, partTwo)
        }
      }
    }

    return blockParticipationsSorted
  },

  /**
   * Calculate need to add time gaps between exercises in block type sessions that simulate mixed type
   * @param block current block
   * @param session current session
   * @param context additional data needed: rotationType, blocks, blockParticipations, discipline, exerciseTypes
   */
  prepareBlockMixedTiming: function(block, session, context) {
    const rotationType = context.discipline.rotationType
    const secondaryType = this.getSessionRotationTypeSecondary(session, context)

    if (rotationType !== 'block' || secondaryType !== 'mixed' || session.sets !== 2) {
      return {
        addStartGap: false,
        addExerciseTime: 0,
      }
    }

    let addStartGap = false
    let addExerciseTime = 0

    const otherBlock = context.blocks.find(b => session.id === b.sessionId && b.set !== block.set && b.index === block.index)

    const blockParts = context.blockParticipations.filter(bp => bp.blockId === block.id)
    const otherBlockParts = context.blockParticipations.filter(bp => bp.blockId === otherBlock.id)

    if (blockParts.length === otherBlockParts.length) {
      addStartGap = (block.set === 2)
      addExerciseTime = blockParts.length
    } else if (blockParts.length >= otherBlockParts.length) {
      addExerciseTime = otherBlockParts.length
    } else {
      addStartGap = true
      addExerciseTime = blockParts.length
    }

    return {
      addStartGap,
      addExerciseTime,
    }
  },

}
