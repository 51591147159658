'use strict'

import store from "@/store"
import Vue from "vue"

export default {
  getEventDiscipline: function() {
    const id = store.state.eventDiscipline.eventDisciplineId
    return store.state.eventDisciplines.items.find(e => e.id === id)
  },

  getRounds: function() {
    const eds = this.getEventDiscipline()
    return eds.rounds
  },

  getRound: function(index) {
    return this.getRounds().find(r => r.index === index)
  },

  /** returns the index of the next round for a specific rankingType or false if the round is final */
  nextRound: function(round, rankingType) {
    const roundRankingType = round.rankings.find(r => r.rankingType === rankingType)
    if (roundRankingType.nextRound >= 0) {
      return roundRankingType.nextRound
    }
    return false
  },

  /** returns the index of the previous round for a specific rankingType or false if no previous round is found */
  prevRound: function(round, rankingType) {
    const rounds = this.getRounds()

    const prevRound = rounds.find(r => {
      if (r.index === round.index) return false

      const roundRanking = r.rankings.find(r => r.rankingType === rankingType)
      if (roundRanking) {
        return roundRanking.nextRound === round.index
      }

      return false
    })

    return prevRound ? prevRound.index : -1
  },

  createRound: function(index, discipline= null) {
    if (! discipline) {
      discipline = store.state.eventDiscipline.discipline
    }

    const rankings = [{
      rankingType: 'AA',
      enabled: true,
      nextRound: '',
    }]

    if (discipline.team) {
      rankings.push({
        rankingType: 'TEAM-AA',
        enabled: false,
        nextRound: '',
      })
    }
    if (discipline.events) {
      rankings.push({
        rankingType: 'EVENTS',
        enabled: false,
        nextRound: '',
      })
    }
    if (discipline.events && discipline.team) {
      rankings.push({
        rankingType: 'TEAM-EVENTS',
        enabled: false,
        nextRound: '',
      })
    }

    return {
      name: Vue.i18n.t('round') + ' ' + (index+1),
      index,
      rankings,
      mainRankingType: 'AA',
    }
  }
}
